/* SCSS HEX */
$resolution-blue:#0a1c88ff;
$resolution-blue-light:#0d26b8;
$yellow-green: #91cf50ff;
$tufts-blue: #528cd5ff;
$barbie-pink: #e10c82ff;
$red: #fe0000ff;
$yellow-orange-color-wheel: #fe9900ff;
$light-gray: #d3d3d3;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-right: linear-gradient(90deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-bottom: linear-gradient(180deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-left: linear-gradient(270deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-top-right: linear-gradient(45deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-bottom-right: linear-gradient(135deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-top-left: linear-gradient(225deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-bottom-left: linear-gradient(315deg,#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);
$gradient-radial: radial-gradient(#91cf50ff, #528cd5ff, #e10c82ff, #fe0000ff, #fe9900ff);

:export {
  resolution-blue: $resolution-blue;
  yellow-green: $yellow-green;
  tufts-blue: $tufts-blue;
  barbie-pink: $barbie-pink;
  red: $red;
  yellow-orange-color-wheel: $yellow-orange-color-wheel;
}

body {
  background: $gradient-top-left;
  height: 103vh;
  overflow: hidden;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiGrid-container .grid {
  background-color: $resolution-blue;
  border-radius: 10px;
}

.MuiPaper-root.Paper {
  height: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  color: black;
}

.UsedNumbers,.FixedUsedNumberBoard  {
  height: 80vh;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.UsedNumbers,.FixedUsedNumberBoard .Title{
  color: white;
  margin-bottom: 6px;
  margin-top: 6px;
  min-height: 1rem;
}

.UsedNumbers .Paper{
  max-height: 4rem;
}

.UsedNumbers .FixedNumber.MuiGrid-item {
  padding: 3px;
}

.FixedUsedNumberBoard .FixedNumber.MuiGrid-item {
  padding: 3px;
}

.FixedNumber .Number-Active {
  background-color: $yellow-green;
  opacity: 0.5;
  border-radius: 16px;
  padding: 6px;
  min-width: 18px;
  text-align: center;
}

.MuiPaper-root.Paper.CurrentNumber {
  height: 75vh;
}

.CurrentNumber {
  font-size: 50vmin;
}

.NumberContainer {
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header .MuiFab-root.Settings {
  color: white;
  background-color: $resolution-blue;
}

.Header .MuiFab-root.Settings:hover {
  background-color: $resolution-blue-light;
}

.Header .Logo {
  height: 4rem;
}

.Tools-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tools {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $resolution-blue;
  width: max-content;
  height: 48px;
  padding: 0 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
}

.Tools .Game-name {

}

.Tools .Next.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border:$resolution-blue 5px solid;
  height: 10vh;
  width: 10vh;
  border-radius: 10vh;
  color: $resolution-blue;
}

.Tools .New.Button {
  background-color: white;
  color: $resolution-blue;
  width: max-content;
  padding: 8px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
  border:$resolution-blue 5px solid;
  margin-right: -14px;
}

.Tools .Button:hover, .Tools .Next.Button:hover, .Tools .New.Button:hover {
  background-color: $resolution-blue-light;
  color: white;
}

.BMC {
  height: 60px !important;
  width: 217px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ffffffa0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: $resolution-blue;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffffa0;
  border-radius: 12px;
}